<script>
import Btn from "@/components/Form/Btn.vue";

export default {
    name: "PhoneList",

    props: {
        phones: {
            type: Array,
            required: true
        }
    }
}
</script>

<template>
    <div class="phone-list">
        <div
            v-for="(data, i) in phones" :key="'dd_'+ index + i"
            class="phone-number"
        >
            <a :href="'tel:' + data.phone" class="phone-number__value">{{ formatPhoneNumber(data.phone ?? '') }}</a>
            <small class="phone-number__note" v-if="data.field !== 'null'">{{ data.field }}</small>
        </div>
    </div>
</template>

<style scoped lang="scss">
.phone-list {

}

.phone-number {
    display: flex;
    flex-direction: column;
    line-height: 1;
    margin-bottom: $spacer * 4;
    white-space: nowrap;
    gap: $spacer;

    a.phone-number__value {
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        text-decoration: none;
        color: var(--text-color);

        &:hover {
            text-decoration: underline;
        }
    }

    .phone-number__note {
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
    }
}

.phone-number__note {
    opacity: .6;
}
</style>
