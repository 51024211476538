<template>
    <PageLayout>
        <template v-slot:default>
            <TabsNav :items="breadcrumbs()[0]['items']"/>

            <div class="d-flex justify-space-between mb-6">
                <div class="d-flex align-center">
                    <h1>
                        <template v-if="currentFilter">
                            {{ currentFilter.name }}
                        </template>
                        <template v-else>
                            {{ $t('uveds') }}
                        </template>
                    </h1>

                    <Btn
                        :disabled="userBlock"
                        icon
                        class="ml-2 mr-2"
                        color="icon-button"
                        @click="dialogImportFile=true"
                        :title="$t('assign_new_client')"
                    >
                        <v-icon class="mx-2" size="18">icon-data-file-add</v-icon>
                    </Btn>

                    <v-icon v-if="$vuetify.breakpoint.mdAndUp" size="22" class="" :disabled="userBlock" color="secondary" @click="filter_show===true ? filter_show=false : filter_show=true" :title="$t['filter_add']">mdi-filter-plus</v-icon>
                </div>
                <div class="text-right">
                    <Btn
                        v-if="permittedForEditTypeClientItems.length > 0 && $vuetify.breakpoint.smAndUp"
                        :disabled="userBlock"
                        :to="{name: 'uved.create'}"
                        color="secondary"
                    >
                        {{ $t('uved.add') }}
                    </Btn>

                    <Teleport to="#app" v-if="!$vuetify.breakpoint.smAndUp">
                        <div class="fab__wrapper">
                            <Btn
                                v-if="permittedForEditTypeClientItems.length > 0"
                                :disabled="userBlock"
                                :to="{name: 'uved.create'}"
                                fab
                                small
                                color="secondary"
                                :title="$t('uved.add')"
                            ><v-icon>mdi-plus</v-icon></Btn>
                        </div>
                    </Teleport>
                </div>
            </div>

            <div class="mobile-filter mb-6" v-if="$vuetify.breakpoint.smAndDown">
                <v-icon
                    class="mobile-filter__btn mr-2"
                    :disabled="userBlock"
                    color="secondary"
                    @click="filter_show === true ? filter_show = false : filter_show = true"
                    :title="$t['filter_add']"
                >mdi-filter-plus</v-icon>

                <FilterMobileLinks
                    :links="[
                        ...system_filters,

                        ...baseItems.map((item) => {
                            return {
                                ...item,
                                title: item.name
                            }
                        }),

                        ...permittedForListTypeClientItems.map((item) => {
                            return {
                                ...item,
                                title: item.name
                            }
                        }),

                        ...userFilters.map((item) => {
                            return {
                                ...item,
                                title: item.name
                            }
                        }),
                    ]"
                />
            </div>

            <FilterComponent
                class="mb-10"
                v-if="filter_show && filterData"
                :filterData="filterData"
                :currentFilter="currentFilter"
                :value="filterValues"
                :loading="loading"
                :invalid="invalid"
                entity="uved"
                @input="filterValues = $event"
                @submit="submitForm"
                @filterSaved="filterSaved"
                @filterDeleted="filterDeleted"
            />

            <template v-if="$vuetify.breakpoint.smAndDown">
                <ListItem
                    v-for="item in uveds"
                    :item="item"
                    :key="item.id"
                    :columns="tableColumns"
                    :userBlock="userBlock"

                    @deleteItem="deleteUved(item)"
                >
                    <template v-slot:phone="{ item, index }">
                        <div class="phone">
                            <div>
                                <div v-if="item.phones.length > 1" class="triangle_right cursor-pointer" :id="'triangle_'+index" @click="showPhone(index)"></div>
                                <div v-if="typeof (item.phones[0]) !== 'undefined'" class="phone_number" :class="item.phones.length === 1 ? 'indent_phone': ''">
                                    {{ formatPhoneNumber(item.phones[0].phone ?? '') }}
                                    <span>{{ item.phones[0].field }}</span>
                                </div>
                            </div>
                            <div class="display_none phone_pad" :class="'triangle_all_'+index">
                                <div
                                    v-for="(data, i) in item.phones.slice(1)" :key="'dd_'+index+i"
                                >
                                    {{ formatPhoneNumber(data.phone ?? '') }}
                                    <span v-if="data.field !== 'null'">{{ data.field }}</span>
                                </div>
                            </div>
                        </div>
                    </template>
                    <template v-slot:type_client="{ item, index }">
                        <span>{{ item.type_client.name }}</span>
                    </template>
                    <template v-slot:base="{ item, index }">
                        <span v-if="item.base">{{ item.base.name }}</span>
                    </template>
                    <template v-slot:email="{ item, index }">
                        <span v-if="item.emails && item.emails.length" v-html="item.emails.join('<br />')"></span>
                    </template>
                    <template v-slot:type_uved="{ item, index }">
                        <span>{{ item.type_uved === 1 ? $t(`columns.${entityName}.legal_entity`) : $t(`columns.${entityName}.personal`) }}</span>
                    </template>

                    <template v-slot:responsible="{ item }">
                        <Users :users="item.responsible" />
                    </template>
                </ListItem>
            </template>
            <template v-else>
                <ResizableTable
                    class="main-data-table elevation-0 uveds-table"
                    :rows="uveds"
                    :columns="tableColumns"
                    :sort-by="sortBy"
                    :entityName="entityName"
                    :sort-dir="sortDir"

                    @sort="sortItems"
                    @columnMoved="onColumnsMoved"
                    @columnResized="onColumnResized"
                    @columnSelect="onColumnSelect"
                >
                    <template v-slot:name="{ item, index }">
                        <v-hover v-slot="{ hover }">
                            <div>
                                <v-btn v-if="item.permissions.includes('uved.read_detail')" :disabled="userBlock" text plain :to="'/uved/' + item.uuid + '/show'" class="cursor-pointer font-weight-bold button_link">
                                    {{ item.name }}
                                </v-btn>
                                <span v-else class="font-weight-bold">
                                    {{ item.name }}
                                </span>
                                <v-icon v-if="hover && item.permissions.includes('uved.delete')"  @click="deleteUved(item)" :title="$t('delete')">
                                    mdi-delete-outline mdi-18px
                                </v-icon>
                            </div>
                        </v-hover>
                    </template>

                    <template v-slot:messages="{ item }">
                        <div class="message-budges">
                            <span class="message-budge" v-if="item.unread_messages_count > 0">
                                <v-icon>icon icon-messages</v-icon>
                            </span>
                            <span class="message-budge message-budge--with_mentioning" v-if="item.unread_messages_with_mentioning_count > 0">
                                <v-icon>mdi mdi-at</v-icon>
                            </span>
                            <span class="message-budge" v-if="item.notification_count > 0">
                                <v-icon>icon-notifications</v-icon>
                            </span>
                        </div>
                    </template>

                    <template v-slot:phone="{ item, index }">
                        <PhoneList :phones="item.phones"></PhoneList>
                    </template>
                    <template v-slot:type_client="{ item, index }">
                        <span>{{ item.type_client.name }}</span>
                    </template>
                    <template v-slot:base="{ item, index }">
                        <span v-if="item.base">{{ item.base.name }}</span>
                    </template>
                    <template v-slot:email="{ item, index }">
                        <div class="d-flex flex-column">
                            <a class="email" v-for="(mail, i) in item.emails" :href="'mailto:' + email" :key="k" :title="email">{{ mail }}</a>
                        </div>
                    </template>
                    <template v-slot:type_uved="{ item, index }">
                        <span>{{ item.type_uved === 1 ? $t(`columns.${entityName}.legal_entity`) : $t(`columns.${entityName}.personal`) }}</span>
                    </template>
                    <template v-slot:responsible="{ item }">
                        <Users :users="item.responsible" />
                    </template>
                    <template v-slot:created_at="{ item }">
                        <DateTime :date="item.created_at"></DateTime>
                    </template>
                    <template v-slot:updated_at="{ item }">
                        <DateTime :date="item.updated_at"></DateTime>
                    </template>
                </ResizableTable>
            </template>

            <div class="main-pagination">
                <SelectInput
                    id="pagination-variants"
                    :value="options.itemsPerPage"
                    :items="perPageItems"
                    :label="$t('items_per_page')"
                    :disabled="loading"
                    class="main-pagination__per-page"
                    @input="onPerPageChange($event)"
                    hide-label
                    background-color="white"
                ></SelectInput>
                <TextInput
                    id="my_page"
                    :label="$t('page')"
                    :hideLabel="true"
                    :dark="true"
                    v-model="my_page"
                    require
                    @keydown="goToPage"
                ></TextInput>
                <v-pagination :key="page" v-model="page" :total-visible="totalVisiblePag"  flat class="main-pagination__pagination pagination_table" :length="pageCount" :disabled="loading" @input="onPageChange"></v-pagination>
            </div>

            <v-dialog
                fullscreen
                hide-overlay
                transition="dialog-bottom-transition"
                v-model="dialogImportFile"
            >
                <v-card>
                    <v-toolbar
                        color="primary"
                        dark
                    >{{ $t('add_company') }}
                    </v-toolbar>
                    <v-card-text>
                        <v-col cols="12">
                            <div class="app-container">
                                <upload-excel-component :on-success="handleSuccess" :before-upload="beforeUpload"/>
                            </div>
                        </v-col>
                        <v-col cols="12">
                            <v-data-table :headers="tableHeader" :items="tableData"
                                          height="300"
                                          mobile-breakpoint="0"
                                          :options.sync="options"
                                          :loading="loading" :locale="lang"
                                          :loading-text="$t('loading_please_wait')" class="bordered-table elevation-1"
                                          item-key="bordered-table"
                                          hide-default-header
                                          hide-default-footer>

                                <template v-slot:header="{ props }">
                                    <th style="min-width: 250px"
                                        v-for="(head, index) in props.headers" :key="head.value">
                                        <v-select
                                            v-model="head.field"
                                            :items="headerOptions"
                                            item-text="name"
                                            item-value="value"

                                            label="Выберите заголовки"
                                            @change="updateKey(props.headers[index].value, head)"
                                        >
                                            <template v-slot:append>
                                                <v-btn @click="clearField(props.headers[index].value, head)" icon small class="pr-5">
                                                    <v-icon>mdi-close</v-icon>
                                                </v-btn>
                                            </template>

                                        </v-select>
                                    </th>
                                </template>

                            </v-data-table>
                        </v-col>
                    </v-card-text>
                    <v-card-actions class="justify-end">
                        <v-btn
                            text
                            @click="clearImport()"
                        >{{ $t('close') }}
                        </v-btn>
                        <v-btn
                            text
                            @click="saveImport()"
                        >{{ $t('add') }}
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </template>

        <template v-slot:side>
            <v-list v-if="$vuetify.breakpoint.mdAndUp" :disabled="userBlock" class="filter_left">
                <v-list-group :ripple="false" v-model="group1" class="px-0">
                    <template v-slot:activator class="px-0">
                        <v-list-item-title :ripple="false" class="main_filter">{{ $t('system_filters') }}</v-list-item-title>
                    </template>

                    <v-list-item :ripple="false" v-for="(item, i) in system_filters" :key="i" :to="item.url" :class="{'active-item': isSameRouteWithQueryParams($route, item.url)}">
                        <v-list-item-title v-text="item.title"></v-list-item-title>
                        <v-list-item-icon v-text="item.count" class="filter_count"></v-list-item-icon>
                    </v-list-item>
                </v-list-group>
            </v-list>

            <v-list :disabled="userBlock" v-if="baseItems.length > 0 && $vuetify.breakpoint.mdAndUp" class="filter_left">
                <v-list-group :ripple="false" v-model="group2" class="px-0">
                    <template v-slot:activator class="px-0">
                        <v-list-item-title :ripple="false" class="main_filter">{{ $t('additional') }}</v-list-item-title>
                    </template>
                    <v-list-item :ripple="false" v-for="(item, i) in baseItems" :key="i" :to="item.url" :class="item.id === base ? 'active' : ''">
                        <v-list-item-title v-text="item.name" class="cursor-pointer"></v-list-item-title>
                        <v-list-item-icon v-text="item.count" class="filter_count"></v-list-item-icon>
                    </v-list-item>
                </v-list-group>
            </v-list>

            <v-list :disabled="userBlock" class="filter_left" v-if="permittedForListTypeClientItems.length > 0 && $vuetify.breakpoint.mdAndUp">
                <v-list-group :ripple="false" v-model="group3" class="px-0">
                    <template v-slot:activator class="px-0">
                        <v-list-item-title :ripple="false" class="main_filter">{{ $t('type_client') }}</v-list-item-title>
                    </template>
                    <v-list-item :ripple="false" v-for="(item, i) in permittedForListTypeClientItems" :key="i" :to="item.url" :class="{'active-item': isSameRouteWithQueryParams($route, item.url)}">
                        <v-list-item-title v-text="item.name" class="cursor-pointer"></v-list-item-title>
                        <v-list-item-icon v-text="item.count" class="filter_count"></v-list-item-icon>
                    </v-list-item>
                </v-list-group>
            </v-list>

            <v-list :disabled="userBlock" v-if="userFilters.length > 0 && $vuetify.breakpoint.mdAndUp" class="filter_left">
                <v-list-group :ripple="false" v-model="userFiltersGroup" class="px-0">
                    <template v-slot:activator class="px-0">
                        <v-list-item-title :ripple="false" class="main_filter">{{ $t('user_filters') }}</v-list-item-title>
                    </template>
                    <v-list-item :ripple="false" v-for="(item, i) in userFilters" :key="i" :class="{'active-item': isSameRouteWithQueryParams($route, item.url)}" :active-class="'no-active'" :to="item.url">
                        <v-list-item-title v-text="item.name" class="cursor-pointer"></v-list-item-title>
                        <v-list-item-icon v-text="item.count" class="filter_count"></v-list-item-icon>
                    </v-list-item>
                </v-list-group>
            </v-list>
        </template>
    </PageLayout>
</template>
<script>
import {mapGetters} from "vuex"
import {mask} from 'vue-the-mask'
import UploadExcelComponent from '../components/UploadExcel/index';
import FilterComponent from "../components/FilterComponent.vue";
import FilterView from "@/plugins/mixins/FilterView";
import ResourceView from "@/plugins/mixins/ResourceView";
import _ from 'lodash';
import PageLayout from "@/components/Leentech/PageLayout.vue";
import SelectInput from "@/components/Form/SelectInput.vue";
import Btn from "@/components/Form/Btn.vue";
import NavMixin from "@/plugins/mixins/Nav";
import TabsNav from "@/components/Leentech/TabsNav.vue";
import ResizableTable from "@/components/Leentech/ResizableTable.vue";
import ListItem from "@/components/Uved/ListItem.vue";
import FilterMobileLinks from "@/components/FilterMobileLinks.vue";
import Teleport from 'vue2-teleport';
import Users from "@/components/Users.vue";
import TextInput from "@/components/Form/TextInput.vue";
import DateTime from "@/components/Leentech/DateTime.vue";
import PhoneList from "@/components/Leentech/PhoneList.vue";


export default {
    name: "Uveds",
    directives: {
        mask,
    },
    components: {
        PhoneList,
        DateTime,
        Users,
        FilterMobileLinks,
        ListItem,
        ResizableTable,
        TabsNav,
        SelectInput,
        FilterComponent,
        UploadExcelComponent,
        PageLayout,
        Btn,
        Teleport,
        TextInput
    },
    mixins: [
        FilterView,
        ResourceView,
        NavMixin
    ],
    data() {
        return {
            entityName: 'uved',
            invalid: false,
            fab: false,
            phone: null,
            uved: null,
            email: null,
            uveds: [],
            uvedItems: [],
            type_uved: null,
            type_uvedItems: [
                {
                    id: null,
                    name: this.$t('all')
                },
                {
                    id: 1,
                    name: this.$t('company')
                },
                {
                    id: 2,
                    name: this.$t('human')
                }
            ],
            sortBy: "last_comment_date",
            sortDir: false,
            page: 1,
            pageCount: 0,
            totalVisiblePag: 7,
            totalUveds: 0,
            options: {
                ...FilterView.data().options,
                itemsPerPage: 10,
                sortBy: [
                    "last_comment_date"
                ],
            },
            loading: false,
            loadingCities: false,
            city: null,
            cityItems: [],
            citySearching: null,
            tableData: [],
            tableHeader: [],
            dialogImportFile: false,
            system_filters: [
                {
                    'title': this.$t('all_contacts'),
                    'count': '-',
                    'url': {
                        path: '/uved'
                    }
                },
                {
                    'title': this.$t('my_clients'),
                    'count': '-',
                    'action': 'my_uved',
                    'url': {
                        path: '/uved',
                        query: {
                            filter: {
                                my: {
                                    eq: '1'
                                },
                            }
                        }
                    }
                },
                {
                    'title': this.$t('favorite'),
                    'count': '-',
                    'action': 'favorite',
                    'url': {
                        path: '/uved',
                        query: {
                            filter: {
                                favorite: {
                                    eq: '1'
                                },
                            }
                        }
                    }
                },
            ],
            Additional: [],
            group1: true,
            group2: true,
            group3: true,
            userFiltersGroup: true,
            filter_show: false,
            base: null,
            baseItems: [],
            type_client: null,
            typeClientItems: [],
            favorite: null,
            my_uved: null,
            uved_filter: 'all',
            my_page:null,

            headerOptions: []
        };
    },

    computed: {
        ...mapGetters(['userBlock', 'lang', 'itemsPerPage', 'perPageItems', 'phoneMask']),

        permittedForListTypeClientItems() {
            return this.typeClientItems.filter((typeClient) => {
                return typeClient.permissions.filter((permission) => {
                    return permission.permission.name === 'uved.read_list'
                }).length > 0
            });
        },

        permittedForEditTypeClientItems() {
            return this.typeClientItems.filter((typeClient) => {
                return typeClient.permissions.filter((permission) => {
                    return permission.permission.name === 'uved.edit'
                }).length > 0
            });
        },

        currentFilter() {
            let filterId = parseInt(this.$route.query.filterId);

            if (filterId && this.userFilters) {
                let currentFilter = _.find(this.userFilters, (item) => {
                    return item.id === filterId;
                })

                if (currentFilter) {
                    return currentFilter;
                }
            }

            return null;
        }
    },

    mounted() {
        this.init();
        this.getBases()
        this.getTypeClients()
    },

    methods: {
        init() {
            this.getItems();
            this.getFilters();
        },
        goToPage(event) {
            if (event.key === 'Enter' || event.keyCode === 13) {
                let pageNumber = Number(this.my_page);
                if (!isNaN(pageNumber)) {
                    if (pageNumber >= 1 && pageNumber <= this.pageCount) {
                        this.options.page = pageNumber;
                        this.page = pageNumber;
                        this.onPageChange(pageNumber);
                        this.getUveds('');
                    }
                }
            }
        },
        showPhone(index) {
            const element = this.$el.querySelector('#triangle_' + index);
            element.classList.toggle('triangle_down');
            const element_all = this.$el.querySelector('.triangle_all_' + index);


            const hasClass = element_all.classList.contains('display_none');
            if (hasClass) {
                element_all.classList.remove('display_none');
                element_all.style.display = 'block';
            } else {
                element_all.classList.add('display_none');
                element_all.style.display = 'none';
            }
        },

        rowClass() {
            return (!this.$vuetify.breakpoint.xsOnly) ? 'rowClass' : 'rowMobiClass';
        },

        canSort(column) {
            if (column === 'last_comment_date') {
                return true;
            }
            // Здесь вы можете определить, можно ли сортировать столбец
            // Например, можете вернуть true для всех столбцов, которые могут быть сортированы
            return false;
        },

        clearField(oldVal, head) {
            if (oldVal) {
                const oldKey = oldVal;
                const newKey = head.field_clear;
                for (let i = 0; i < this.tableData.length; i++) {
                    Object.entries(this.tableData[i]).forEach(([key, item]) => {
                        if (key === oldKey) {
                            this.tableData[i][newKey] = this.tableData[i][key]
                            delete this.tableData[i][oldKey];
                        }
                    });
                }
                head.field = newKey;
                head.value = newKey;
                head.text = newKey;
                const foundObject = this.headerOptions.find(obj => obj.value === oldKey);
                if (foundObject) {
                    foundObject.disabled = false
                }
            }
        },

        updateKey(oldVal, head) {
            if (oldVal) {
                const oldKey = oldVal;
                const newKey = head.field;
                if (oldKey === newKey) {
                    return
                }
                for (let i = 0; i < this.tableData.length; i++) {
                    Object.entries(this.tableData[i]).forEach(([key, item]) => {
                        if (key === oldKey) {
                            this.tableData[i][newKey] = this.tableData[i][key]
                            delete this.tableData[i][oldKey];
                            head.value = newKey;
                            head.show = newKey;
                        }
                    });
                }
                const foundObject = this.headerOptions.find(obj => obj.value === newKey);
                if (foundObject) {
                    foundObject.disabled = true
                }
            }
        },

        beforeUpload(file) {
            const isLt1M = file.size / 1024 / 1024 < 1

            if (isLt1M) {
                return true
            }

            this.$message({
                message: 'Please do not upload files larger than 1m in size.',
                type: 'warning'
            })
            return false
        },

        handleSuccess({results, header}) {
            this.tableData = results
            for (let i = 0; i < header.length; i++) {
                this.tableHeader.push(
                    {
                        text: header[i],
                        align: "left",
                        sortable: false,
                        value: header[i],
                        field: 'column_' + i,
                        field_clear: 'column_' + i,

                    }
                );
            }
        },

        showUved(item) {
            this.$router.push({
                name: 'uved.show',
                params: {
                    id: item.uuid
                }
            })
        },

        editUved(item) {
            this.$router.push({
                name: 'uved.edit',
                params: {
                    id: item.uuid
                }
            })
        },

        async deleteUved(item) {
            if (confirm(this.$t('delete_uved'))) {
                var _this = this
                this.loading = true;
                await this.$http
                    .delete(`admin/uved/${item.id}`)
                    .then(res => {
                        this.$toastr.success(this.$t('uved_has_been_deleted'))
                        this.getUveds()
                    })
                    .catch(err => {
                        this.$toastr.success(this.$t('uved_has_not_been_deleted'))
                        if (err.body.message) {
                            for (let prop in err.body.errors) {
                                if (hasOwnProperty.call(err.body.errors, prop)) {
                                    if (_this.$refs[prop]) {
                                        _this.$refs[prop].setErrors([
                                            err.body.errors[prop][0]
                                        ])
                                    }
                                }
                            }
                            if (!err.body.errors) {
                                this.$toastr.error(err.body.message)
                            }
                        }
                    })
                    .finally(end => {
                        this.loading = false
                    })
            }
        },

        async getItems() {
            await this.getUveds();
        },

        async getUveds(type) {
            this.loading = true;
            const {
                sortBy,
                sortDesc,
                page,
                itemsPerPage
            } = this.options;

            let params = {};

            if (this.filterValues) {
                params.fltr = this.filterValues;
            }

            if (sortBy !== undefined && sortBy[0] !== undefined) {
                params.sortBy = sortBy[0];
            } else {
                params.sortBy = 'last_comment_date';
            }

            if (sortDesc !== undefined && sortDesc[0] !== undefined) {
                params.sortDir = sortDesc[0] ? 'asc' : 'desc';
            } else {
                params.sortDir = 'asc';
            }

            if (page !== undefined) {
                if (type === 'search') {
                    params.page = 1
                } else {
                    params.page = page
                }
            }

            if (itemsPerPage !== undefined) {
                params.perPage = itemsPerPage;
            } else {
                params.perPage = this.itemsPerPage;
            }

            await this.$http
                .get("admin/uved", {
                    params: params,
                })
                .then(res => {
                    this.uveds = res.body.data
                    if (this.page > res.body.meta.last_page) {
                        this.onPageChange(res.body.meta.last_page);
                    }
                    this.totalUveds = res.body.meta.total
                    this.pageCount = res.body.meta.last_page
                })
                .catch(err => {
                    this.uveds = []
                    this.totalUveds = 0
                    this.$toastr.error(this.$t('failed_to_get_list_uveds'))
                })
                .finally(end => {
                    this.loading = false
                })
        },

        clearImport() {
            this.tableData = [];
            this.tableHeader = [];
            this.dialogImportFile = false;
            this.headerOptions.forEach(function (item) {
                item.disabled = false
            });
        },

        async saveImport() {
            var _this = this;
            this.progress = 0
            this.loading = true
            var formData = new FormData()

            if (this.tableData) {
                formData.append('table_data', JSON.stringify(this.tableData))
            }

            await this.$http
                .post('admin/import/uved', formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    },
                    progress(e) {
                        if (e.lengthComputable) {
                            _this.progress = Math.round((e.loaded / e.total) * 100)
                        }
                    }
                })
                .then(res => {
                    this.$toastr.success(this.$t('import_uved_has_been_added'))

                    //this.forceRerender()
                })
                .catch(err => {
                    this.$toastr.error(this.$t('import_uved_has_not_been_added'))
                    if (err && err.body && err.body.message) {
                        for (let prop in err.body.errors) {
                            if (hasOwnProperty.call(err.body.errors, prop)) {
                                if (_this.$refs[prop]) {
                                    _this.$refs[prop].setErrors([
                                        err.body.errors[prop][0]
                                    ])
                                }
                            }
                        }
                        if (!err.body.errors) {
                            this.$toastr.error(err.body.message)
                        }
                    }
                })
                .finally(end => {
                    this.progress = 0
                    this.loading = false
                })


        },

        async getBases() {
            this.loading = true
            let params = {}
            await this.$http
                .get("admin/base", {
                    params: params,
                })
                .then(res => {
                    this.baseItems = res.body.data.map((item) => {
                        return {
                            ...item,
                            url: {
                                path: '/uved',
                                query: {
                                    filter: {
                                        base: {
                                            eq: item.id
                                        }
                                    }
                                }
                            }
                        }
                    })
                })
                .catch(err => {
                    this.baseItems = []
                })
                .finally(end => {
                    this.loading = false
                })
        },

        async getTypeClients() {
            this.loading = true
            let params = {}
            await this.$http
                .get("admin/type_client", {
                    params: params,
                })
                .then(res => {
                    this.typeClientItems = res.body.data.map((item) => {
                        return {
                            ...item,
                            url: {
                                path: '/uved',
                                query: {
                                    filter: {
                                        type_client: {
                                            eq: item.id
                                        }
                                    }
                                }
                            }
                        }
                    })
                })
                .catch(err => {
                    this.typeClientItems = []
                })
                .finally(end => {
                    this.loading = false
                })
        },

        sendRoute(item) {
            this.base = item.id;
            this.type_client = null;
            this.uved_filter = null;
            this.getUveds();
        },

        sendRouteTypeClient(item) {
            this.type_client = item.id;
            this.uved_filter = null
            this.base = null
            this.getUveds();
        },
    }
}
</script>

<style lang="scss">
/* Добавляем стили для границы внутри таблицы */
.bordered-table td,
.bordered-table th {
    border: 1px solid #ccc; /* Пример стиля границы */
    padding: 5px; /* Добавляем отступы по желанию */
}

.uveds-table {
    .rowHeadClass th,
    .rowClass td {
        width: 20% !important;

        &:nth-child(1) {
            width: 28% !important;
        }

        &:nth-child(2) {
            width: 28% !important;
        }

        &:nth-child(3) {
            width: 22% !important;
        }

        &:nth-child(4) {
            width: 22% !important;
        }

        &:last-child {
            text-align: right !important;
        }
    }
}

.el-table__body-wrapper {
    overflow-x: auto;
}

.el-table__body {
    width: auto !important;
}
</style>
