<script>
export default {
    name: "DateTime",

    props: {
        date: {
            type: String,
            required: true
        },

        dateFormat: {
            type: String,
            default: 'DD MMMM'
        },

        timeFormat: {
            type: String,
            default: 'HH:mm:ss'
        }
    }
}
</script>

<template>
    <div class="datetime">
        <span>{{ $moment(date).format(dateFormat) }}</span>
        <small>{{ $moment(date).format(timeFormat) }}</small>
    </div>
</template>

<style scoped lang="scss">
.datetime {
    line-height: 1;
    display: flex;
    flex-direction: column;
    gap: $spacer;

    small {
        opacity: 0.8;
    }
}
</style>
